import { LoadingScreen } from '@/components/layout/loading';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { userSelector } from '@/redux/slices/userSlice';

const Home = () => {
  const router = useRouter();
  const { isLoggedIn } = useSelector(userSelector);

  useEffect(() => {
    if(!isLoggedIn) {
      router.push('/login')
    }
  }, [isLoggedIn, router]);

  return <LoadingScreen />
};

export default Home;
